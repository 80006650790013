
import { Component, Vue, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { bus } from "@/main";
import { formatCurrency } from "@/utils/formatters";
import { Mandate, IndexMandatePayload } from "@/store/modules/mandate.store";
import { activityIsDossier } from "@/utils/model-helpers";
import { TeamFunctions } from "@/store/modules/activity-account.store";
import { IndexAccountPayload } from "@/store/modules/account.store";

@Component({
    components: {
        DossierCommission: () => import("@/components/dossier/commission.vue"),
        DossierSettingsDropdown: () => import("@/components/dossier/settings-dropdown.vue"),
        WorkStatusBar: () => import("@/views/auth/dossier/parts/work-status-bar.vue"),
        AssignUsers: () => import("@/components/dossier/assign-users.vue"),
        UserImage: () => import("@/components/general/user-image.vue"),
        UserImageWrapper: () => import("@/components/general/user-image-wrapper.vue"),
    },
})
export default class DossierPartHeader extends Vue {
    @Getter("account/all") accounts!: Account[];
    @Action("account/indexForCurrentOffice") indexAccounts!: (payload: IndexAccountPayload) => Promise<Account[]>;
    @Action("mandate/index") indexMandates!: (payload: IndexMandatePayload) => Promise<Mandate[]>;

    @Prop({ required: true }) activity!: Activity;
    @Prop({ required: true }) property!: Property;

    formatCurrency = formatCurrency;

    teamFunctions = TeamFunctions;

    bus = bus;

    mandate_id: number | null = null;

    get isDossier() {
        return activityIsDossier(this.activity);
    }

    get uniqueAssignees() {
        return [...new Map(this.activity.assigned_to.map((item) => [item.id, item])).values()];
    }

    get isMobile() {
        // @ts-ignore
        return this.$root.isMobile;
    }

    async mounted() {
        if (!this.accounts.length) {
            this.indexAccounts({ q: { or: { roles: ["administrator", "user"] } }, per_page: 99 });
        }

        const mandates = await this.indexMandates({ s: { updated_at: "desc" }, q: { and: { activity_id: Number(this.$route.params.id) } } });

        if (!mandates.length) {
            return;
        }

        this.mandate_id = mandates[0].id;
    }
}
